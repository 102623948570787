import React from 'react';
import FeatherIcon from 'feather-icons-react';
import ReactStars from 'react-rating-stars-component';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Image,
  Card,
  useColorMode,
} from '@chakra-ui/react';

const UserCard2 = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
      <div className="lg:flex md:flex block items-start w-full">
        <div className="lg:w-3/12 md:w-5/12 w-full">
          <h6 className="fsize22 md-fsize20 sm-fsize18 text-dark font-semibold cust-textColor">
            Instructor
          </h6>
          <Card
            className={
              colorMode === 'light'
                ? 'relative border-dark rounded-lg p-4 box-shadow-none mt-4'
                : 'relative rounded-lg p-4 mt-4'
            }
          >
            <div>
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="profile"
                className="lg:w-52 lg:h-52 md:w-40 md:h-40 w-32 h-32 mx-auto rounded-full"
              />
              <h5 className="fsize30 md-fsize26 sm-fsize20 text-dark mt-4 font-semibold cust-textColor">
                Sunny Shetty
              </h5>
              <p className="fsize16 text-gray textColor-light mt-1">
                Founder of critical thinking
              </p>
              <div className="mt-2 flex items-center gap-1">
                <FeatherIcon
                  icon="star"
                  className="flex cust-textColor"
                  size="14"
                />
                <p className="fsize12 text-gray textColor-light">
                  4.5 instructor roasting
                </p>
              </div>
              <div className="mt-2 flex items-center gap-1">
                <FeatherIcon
                  icon="play"
                  className="flex cust-textColor"
                  size="14"
                />
                <p className="fsize12 text-gray textColor-light">4 Cources</p>
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="border-dark rounded-full p-2">
                  <FeatherIcon
                    icon="facebook"
                    size={20}
                    className="cust-textColor"
                  />
                </div>
                <div className="border-dark rounded-full p-2">
                  <FeatherIcon
                    icon="instagram"
                    size={20}
                    className="cust-textColor"
                  />
                </div>
                <div className="border-dark rounded-full p-2">
                  <FeatherIcon
                    icon="linkedin"
                    size={20}
                    className="cust-textColor"
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="lg:w-9/12 md:w-7/12 w-full lg:pl-10 md:pl-5 mt-8">
          <h4 className="fsize24 md-fsize22 sm-fsize20 font-semibold text-dark cust-textColor">
            About Us
          </h4>
          <p className="fsize16 sm-fsize14 mt-1 text-gray textColor-light">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content. Lorem ipsum may be
            used as a placeholder before the final copy is available.
          </p>
          <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-4 mt-6">
            <div className="bg-f4f5f6 cust-bgColor lg:p-4 md:p-3 p-2 rounded-lg cust-shadow flex">
              <div className="lg:w-1/12 md:w-1/12 w-2/12">
                <FeatherIcon
                  icon="award"
                  size="24"
                  className="flex mt-2 cust-textColor"
                />
              </div>
              <div className="lg:w-11/12 md:w-11/12 w-10/12">
                <h6 className="fsize18 md-fsize16 sm-fsize16 font-medium text-dark cust-textColor">
                  Achivement name
                </h6>
                <p className="fsize13 my-1 text-gray textColor-light">
                  2020-2021
                </p>
                <p className="fsize13 text-dark textColor-light">
                  Lorem ipsum may be used as a placeholder before the final copy
                  is available.
                </p>
              </div>
            </div>
            <div className="bg-f4f5f6 cust-bgColor lg:p-4 md:p-3 p-2 rounded-lg cust-shadow flex">
              <div className="lg:w-1/12 md:w-1/12 w-2/12">
                <FeatherIcon
                  icon="award"
                  size="24"
                  className="flex mt-2 cust-textColor"
                />
              </div>
              <div className="lg:w-11/12 md:w-11/12 w-10/12">
                <h6 className="fsize18 md-fsize16 sm-fsize16 font-medium text-dark cust-textColor">
                  Achivement name
                </h6>
                <p className="fsize13 my-1 text-gray textColor-light">
                  2020-2021
                </p>
                <p className="fsize13 text-dark textColor-light">
                  Lorem ipsum may be used as a placeholder before the final copy
                  is available.
                </p>
              </div>
            </div>
            <div className="bg-f4f5f6 cust-bgColor lg:p-4 md:p-3 p-2 rounded-lg cust-shadow flex">
              <div className="lg:w-1/12 md:w-1/12 w-2/12">
                <FeatherIcon
                  icon="award"
                  size="24"
                  className="flex mt-2 cust-textColor"
                />
              </div>
              <div className="lg:w-11/12 md:w-11/12 w-10/12">
                <h6 className="fsize18 md-fsize16 sm-fsize16 font-medium text-dark cust-textColor">
                  Achivement name
                </h6>
                <p className="fsize13 my-1 text-gray textColor-light">
                  2020-2021
                </p>
                <p className="fsize13 text-dark textColor-light">
                  Lorem ipsum may be used as a placeholder before the final copy
                  is available.
                </p>
              </div>
            </div>
            <div className="bg-f4f5f6 cust-bgColor lg:p-4 md:p-3 p-2 rounded-lg cust-shadow flex">
              <div className="lg:w-1/12 md:w-1/12 w-2/12">
                <FeatherIcon
                  icon="award"
                  size="24"
                  className="flex mt-2 cust-textColor"
                />
              </div>
              <div className="lg:w-11/12 md:w-11/12 w-10/12">
                <h6 className="fsize18 md-fsize16 sm-fsize16 font-medium text-dark cust-textColor">
                  Achivement name
                </h6>
                <p className="fsize13 my-1 text-gray textColor-light">
                  2020-2021
                </p>
                <p className="fsize13 text-dark textColor-light">
                  Lorem ipsum may be used as a placeholder before the final copy
                  is available.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <h6 className="fsize24 md-fsize22 sm-fsize20 text-dark font-semibold cust-textColor">
          Cources
        </h6>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4">
          <Card
            className={
              colorMode === 'light'
                ? 'cursor-pointer rounded themeShadow theme-bg'
                : 'cursor-pointer rounded themeShadow theme-bg'
            }
          >
            <div className="relative image-product overflow-hidden rounded-lg">
              <Image
                className="img-h-220 w-full opct"
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
              />
              <div className="absolute left-0 top-0 flex gap-2 p-2 bg-fff">
                <div className="">
                  <p className="fsize14 textColor-light">4.5/5</p>
                </div>
                <div>
                  <FeatherIcon className="filled-blk " icon="star" size={18} />
                </div>
              </div>
            </div>

            <div className="lg:p-4 md:p-3 p-3">
              <div className="">
                <p className="fsize18 sm-fsize16 font-semibold text-black cust-textColor lineClamp2">
                  Motion Graphics: Create a Nice Typography Animations
                </p>
              </div>
              <div className="mt-4">
                <div className="flex items-center justify-between">
                  <p className="fsize16 text-gray font-semibold textColor-light">
                    400 /-
                  </p>
                  <div className="flex items-center gap-1">
                    <FeatherIcon
                      icon="heart"
                      size="18"
                      className="textColor-light"
                    />
                    <FeatherIcon
                      icon="shopping-cart"
                      size="18"
                      className="textColor-light"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card
            className={
              colorMode === 'light'
                ? 'cursor-pointer rounded themeShadow theme-bg'
                : 'cursor-pointer rounded themeShadow theme-bg'
            }
          >
            <div className="relative image-product overflow-hidden rounded-lg">
              <Image
                className="img-h-220 w-full opct"
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
              />
              <div className="absolute left-0 top-0 flex gap-2 p-2 bg-fff">
                <div className="">
                  <p className="fsize14 textColor-light">4.5/5</p>
                </div>
                <div>
                  <FeatherIcon className="filled-blk " icon="star" size={18} />
                </div>
              </div>
            </div>

            <div className="p-4">
              <div className="">
                <p className="fsize18 sm-fsize16 font-semibold text-black cust-textColor lineClamp2">
                  Motion Graphics: Create a Nice Typography Animations
                </p>
              </div>
              <div className="mt-4">
                <div className="flex items-center justify-between">
                  <p className="fsize16 text-gray font-semibold textColor-light">
                    400 /-
                  </p>
                  <div className="flex items-center gap-1">
                    <FeatherIcon
                      icon="heart"
                      size="18"
                      className="textColor-light"
                    />
                    <FeatherIcon
                      icon="shopping-cart"
                      size="18"
                      className="textColor-light"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card
            className={
              colorMode === 'light'
                ? 'cursor-pointer rounded themeShadow theme-bg'
                : 'cursor-pointer rounded themeShadow theme-bg'
            }
          >
            <div className="relative image-product overflow-hidden rounded-lg">
              <Image
                className="img-h-220 w-full opct"
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
              />
              <div className="absolute left-0 top-0 flex gap-2 p-2 bg-fff">
                <div className="">
                  <p className="fsize14 textColor-light">4.5/5</p>
                </div>
                <div>
                  <FeatherIcon className="filled-blk " icon="star" size={18} />
                </div>
              </div>
            </div>

            <div className="p-4">
              <div className="">
                <p className="fsize18 sm-fsize16 font-semibold text-black cust-textColor lineClamp2">
                  Motion Graphics: Create a Nice Typography Animations
                </p>
              </div>
              <div className="mt-4">
                <div className="flex items-center justify-between">
                  <p className="fsize16 text-gray font-semibold textColor-light">
                    400 /-
                  </p>
                  <div className="flex items-center gap-1">
                    <FeatherIcon
                      icon="heart"
                      size="18"
                      className="textColor-light"
                    />
                    <FeatherIcon
                      icon="shopping-cart"
                      size="18"
                      className="textColor-light"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div className="mt-8">
        <h6 className="fsize24 md-fsize22 sm-fsize20 text-dark cust-textColor font-semibold">
          Instructing Rating
        </h6>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4">
          <Card
            className={
              colorMode === 'light'
                ? 'cursor-pointer rounded themeShadow theme-bg bg-white cust-shadow p-6'
                : 'cursor-pointer rounded themeShadow theme-bg bg-white cust-shadow p-6'
            }
          >
            <div className="flex items-center gap-2">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="profile"
                className="w-12 h-12 rounded-full"
              />
              <div className="">
                <h6 className="fsize18 sm-fsize16 leading-5 text-dark cust-textColor font-semibold">
                  Abhishek rathod
                </h6>
                <div className="flex items-center gap-2">
                  <ReactStars
                    classNames="my-0"
                    count={5}
                    size={18}
                    activeColor="#ffd700"
                  />
                  <p className="fsize12 text-gray textColor-light">
                    1 week ago
                  </p>
                </div>
              </div>
            </div>
            <p className="fsize14 mt-2 text-gray textColor-light">
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without relying on meaningful content.
            </p>
            <div className="mt-2 flex items-center gap-2">
              <p className="fsize14 text-gray textColor-light">Helpful</p>
              <FeatherIcon
                icon="thumbs-up"
                size={16}
                className="text-gray textColor-light"
              />
              <FeatherIcon
                icon="thumbs-down"
                size={16}
                className="text-gray textColor-light"
              />
            </div>
            <div className="absolute top-0 right-0 m-2">
              <Menu>
                <MenuButton>
                  <FeatherIcon icon="more-vertical" size="16" />
                </MenuButton>
                <MenuList>
                  <MenuItem className="fsize13">Edit</MenuItem>
                  <MenuItem className="fsize13">Delete</MenuItem>
                </MenuList>
              </Menu>
            </div>
          </Card>
          <Card
            className={
              colorMode === 'light'
                ? 'cursor-pointer rounded themeShadow theme-bg bg-white cust-shadow p-6'
                : 'cursor-pointer rounded themeShadow theme-bg bg-white cust-shadow p-6'
            }
          >
            <div className="flex items-center gap-2">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="profile"
                className="w-12 h-12 rounded-full"
              />
              <div className="">
                <h6 className="fsize18 sm-fsize16 leading-5 text-dark cust-textColor font-semibold">
                  Abhishek rathod
                </h6>
                <div className="flex items-center gap-2">
                  <ReactStars
                    classNames="my-0"
                    count={5}
                    size={18}
                    activeColor="#ffd700"
                  />
                  <p className="fsize12 text-gray textColor-light">
                    1 week ago
                  </p>
                </div>
              </div>
            </div>
            <p className="fsize14 mt-2 text-gray textColor-light">
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without relying on meaningful content.
            </p>
            <div className="mt-2 flex items-center gap-2">
              <p className="fsize14 text-gray textColor-light">Helpful</p>
              <FeatherIcon
                icon="thumbs-up"
                size={16}
                className="text-gray textColor-light"
              />
              <FeatherIcon
                icon="thumbs-down"
                size={16}
                className="text-gray textColor-light"
              />
            </div>
            <div className="absolute top-0 right-0 m-2">
              <Menu>
                <MenuButton>
                  <FeatherIcon icon="more-vertical" size="16" />
                </MenuButton>
                <MenuList>
                  <MenuItem className="fsize13">Edit</MenuItem>
                  <MenuItem className="fsize13">Delete</MenuItem>
                </MenuList>
              </Menu>
            </div>
          </Card>
          <Card
            className={
              colorMode === 'light'
                ? 'cursor-pointer rounded themeShadow theme-bg bg-white cust-shadow p-6'
                : 'cursor-pointer rounded themeShadow theme-bg bg-white cust-shadow p-6'
            }
          >
            <div className="flex items-center gap-2">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="profile"
                className="w-12 h-12 rounded-full"
              />
              <div className="">
                <h6 className="fsize18 sm-fsize16 leading-5 text-dark cust-textColor font-semibold">
                  Abhishek rathod
                </h6>
                <div className="flex items-center gap-2">
                  <ReactStars
                    classNames="my-0"
                    count={5}
                    size={18}
                    activeColor="#ffd700"
                  />
                  <p className="fsize12 text-gray textColor-light">
                    1 week ago
                  </p>
                </div>
              </div>
            </div>
            <p className="fsize14 mt-2 text-gray textColor-light">
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without relying on meaningful content.
            </p>
            <div className="mt-2 flex items-center gap-2">
              <p className="fsize14 text-gray textColor-light">Helpful</p>
              <FeatherIcon
                icon="thumbs-up"
                size={16}
                className="text-gray textColor-light"
              />
              <FeatherIcon
                icon="thumbs-down"
                size={16}
                className="text-gray textColor-light"
              />
            </div>
            <div className="absolute top-0 right-0 m-2">
              <Menu>
                <MenuButton>
                  <FeatherIcon icon="more-vertical" size="16" />
                </MenuButton>
                <MenuList>
                  <MenuItem className="fsize13">Edit</MenuItem>
                  <MenuItem className="fsize13">Delete</MenuItem>
                </MenuList>
              </Menu>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default UserCard2;
