const initialState = {
  menu: [],
  loading: true,
  selectedContrastData: false,
};
const menu_reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'setMenuData':
      return { ...state, menu: action.payload };

    case 'setLoader':
      return { ...state, loading: action.payload };

    case 'SET_CONTRAST':
      return { ...state, selectedContrastData: action.payload };

    default:
      return state;
  }
};

export default menu_reducer;
